import AnimatorPage from 'components/pages/be-an-animator/AnimatorPage';
import Seo from 'components/Seo';
import { graphql } from 'gatsby';
import * as React from 'react';

const Index = () => (
	<div>
		<Seo
			title='Program wcześniejszych płatności'
			keywords='łańcuch dostaw, płynność finansowa, finansowanie dla firm, relacje z dostawcami, finansowanie dostawców, nadwyżka kapitału, dodatkowy zysk'
			description='Poznaj FINTEQ – postaw na doskonałość łańcucha dostaw Twojej firmy, zarządzaj płynnością finansową oraz buduj przewagę konkurencyjną w branży.'
			lang='pl-PL'
			locale='pl_PL'
		/>

		<AnimatorPage />
	</div>
);

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
